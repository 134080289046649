import React, { Component } from 'react'
import Table from 'react-bootstrap/Table';

export default class About extends Component {
  render() {
    return (
      <div className='p-5'>
        <div style={{ backgroundColor: 'antiquewhite' }} className='p-3 rounded-4 mb-4' >

          <p>The International Journal of Education and Allied Sciences (IJEAS) is a bi-annual publication committed to presenting outstanding contributions in the field of Education and Allied Sciences. Our journal serves as a prestigious platform for scholars and researchers to showcase their exceptional work and advancements in these interconnected disciplines. IJEAS continues the legacy of esteemed journals, seeking to improve research quality and address important issues in Education and Allied Sciences. With a particular emphasis on challenges and solutions within educational institutions and society, our journal offers valuable insights to stakeholders.</p>

          <p>
            Our mission revolves around the dissemination of cutting-edge research and scientific methodologies, especially those with broad applications across multiple disciplines. IJEAS publishes original research papers and comprehensive articles covering various sub-areas within the field of education. The primary objective of IJEAS is to foster academic liaising and intellectual culture by promoting the exchange of ideas. We strive to cultivate contemporary schools of thought and generate groundbreaking research theories that resonate with researchers, practitioners, and educators worldwide.</p>

          <p>Within the pages of IJEAS, readers can explore recent trends and emerging issues in educational technology, administration and management, educational psychology, special education, educational policies and provisions, and their interdisciplinary connections. We invite constructive feedback from our esteemed readers, recognizing its pivotal role in promoting growth and refinement. Engaging in an open scholarly conversation, we encourage our audience to actively contribute to the advancement of IJEAS.</p>

          <p>
          Join us on a transformative journey of intellectual exploration, as we endeavor to bring a paradigm shift in the realms of Education and Allied Sciences.
          </p>

        </div>

        <Table className="table table-borderless table-condensed table-hover" style={{ width: "fit-content" }}>

          <tbody>


            <tr>
              <td>ISSN Online</td>
              <td>: 2231-4733</td>

            </tr>

            <tr>
              <td>ISSN Print</td>
              <td>: 0975-8380</td>

            </tr>

            <tr>
              <td>Publication Frequency</td>
              <td>: Bi-annual (July and December)</td>

            </tr>

            <tr>
              <td>Starting Year</td>
              <td>: 2009</td>

            </tr>

            <tr>
              <td>Article processing fee</td>
              <td>: No publication fee</td>

            </tr>

            <tr>
              <td>Subject Coverage</td>
              <td>: Education and Allied Sciences</td>

            </tr>



          </tbody>
        </Table>





      </div>
    )
  }
}
