import React,{useState} from 'react'
import JournalManager from './JournalManager';
import { saveAs } from 'file-saver';
// import pdf1_2020 from '/Journals/ijeas2020.pdf';
// import pdf2_2020 from '/Journals/ijeas2021.pdf';

const Year2023 = () => {
  const pdfFiles = [{
    name: 'IJEAS Vol 15, Number 1',
    url:  '/Journals/Upload_IJEAS.pdf'
  },
  {
    name: 'IJEAS Vol 15, Number 2',
    url: '/Journals/IJEAS_December_2023.pdf'
  }

  ];

  const [selectedPDF, setSelectedPDF] = useState(null);

  const handlePreviewClick = (pdfUrl) => {
    setSelectedPDF(pdfUrl);
  };

  const handleDownloadClick = (pdfUrl, pdfName) => {
    saveAs(pdfUrl, pdfName);
  };

  return (
    <div className='m-4'>
      {pdfFiles.map((pdf) => (
        <div key={pdf.name}>
          <button className='m-3 rounded-3 bg-warning text-dark fs-4' onClick={() => handlePreviewClick(pdf.url)}>Preview {pdf.name}</button>
          <button className='m-3 rounded-3 bg-primary text-white fs-4' onClick={() => handleDownloadClick(pdf.url, `${pdf.name}.pdf`)}>
            Download {pdf.name}
          </button>
        </div>
      ))}
      {selectedPDF && <JournalManager pdfUrl={selectedPDF} />}
    </div>

  );
};

export default Year2023;

