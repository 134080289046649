import React, { Component } from 'react'

export default class Guidelines extends Component {
  render() {
    return (
      <div className='p-5'>
        <h3 className='text-center'>INSTRUCTION FOR AUTHORS</h3>

        <div>


          <ol className="list-group list-group-numbered">
            <li className="list-group-item guideline_list">
              Triplicate copies of manuscripts types in English/Hindi on one side of the A4 size paper should be submitted along with an abstract of not more than 200 words. The length of a paper including tables, diagrams, illustrations etc. should be between 3000 to 5000 words. Papers/Articles should be original and unpublished contributions. Papers should be accompanied by a declaration that the material is original, has not been published elsewhere in part or full and the same has not been submitted for publication in any other book or journal or elsewhere. Leave a margin of at least one inch on all sides of the paper and one and a half inches on the left side of the paper. The electronic copy must be sent to the given email addresses. An article must be in MS Word in Times New Roman/Hindi Kruti Dev 010 in font size 12. 
            </li>

            <li className="list-group-item guideline_list">
              Short communication to review articles, reports of conferences, summaries or views on government reports, debatable issues, etc. is also published.
            </li>

            <li className="list-group-item guideline_list">
              Authors/Publishers are also welcome to send books or book reviews to the editor for the publication of reviews in the journal.
            </li>

            <li className="list-group-item guideline_list">
              The paper once submitted to this journal should not be resubmitted simultaneously to other journals.
            </li>

            <li className="list-group-item guideline_list">
              To ensure anonymity, the author's name, designation, affiliation, official and residential address, and other details about the author should only appear on the first page along with the title of the paper. The second page should start with the title of the paper again followed by text.
            </li>

            <li className="list-group-item guideline_list">
              Footnotes should be numbered consecutively in plain Arabic superscripts. The footnotes, if any, should be typed under the heading 'footnotes' at the end of the paper immediately after 'conclusion'.
            </li>

            <li className="list-group-item guideline_list">
              APA format should be followed.
            </li>

            <li className="list-group-item guideline_list">
              All references should be alphabetically arranged at the end of the text.
            </li>

          </ol>
        </div>
      </div>
    )
  }
}
