
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import About from "./pages/About";
import Home from './pages/Home';
import Contact from "./pages/Contact";
import Guidelines from './pages/Guidelines';
import Navbar from "./components/NavbarComp";
import Editorial from './pages/Editorial';
import { Image } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import logo from './IJEAS_Logo.jpg';
import Login from './components/Login';
import Register from './components/Register';
import Year2009 from './pages/Year2009';
import Year2010 from './pages/Year2010';
import Year2011 from './pages/Year2011';
import Year2012 from './pages/Year2012';
import Year2013 from './pages/Year2013';
import Year2014 from './pages/Year2014';
import Year2015 from './pages/Year2015';
import Year2016 from './pages/Year2016';
import Year2017 from './pages/Year2017';
import Year2018 from './pages/Year2018';
import Year2019 from './pages/Year2019';

import Year2020 from './pages/Year2020';
import Year2021 from './pages/Year2021';
import Year2022 from './pages/Year2022';
import Year2023 from './pages/Year2023';
import Year2024 from './pages/Year2024';
import 'react-toastify/dist/ReactToastify.css';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';








function App() {





  const navigateToLogin = () => {

    window.location.href = '/Login';

  };

  const navigateToRegister = () => {

    window.location.href = '/Register';

  };



  return (

    <>

      <Container fluid style={{padding:"0px", margin:"0px"}} className='bg-light'>
        <Row >
          <Col xs lg="1" style={{padding:"0px,0px", margin:"0px"}}>
          <Image src={logo} width={100} height={100} ></Image>          
          </Col>
          <Col md="auto" xs={9} style={{padding:"0px,0px", margin:"0px"}} className="align-self-center text-start fs-3 fst-italic mx-2">
          <p >International Journal of Education and Allied Sciences (IJEAS)</p>
          </Col>

          {/* <Col xs lg="2" style={{padding:"0px,0px", marginLeft:"20%"}} className='align-self-center justify-content-end'>
          <Button className='m-1' variant="outline-primary border-3 fs-6" onClick={navigateToLogin} >Log In</Button>
          <Button className='m-1' variant="outline-primary border-3 fs-6" onClick={navigateToRegister}>Register</Button>

          
          </Col> */}


        </Row>
      </Container>


      {/* <div className='d-flex flex-cloumn justify-content-between bg-light'>

        <div className='d-flex align-item-start mx-2 '>

          <Image src={logo} width={100} height={100}></Image>
          <p className="align-self-center fs-3 mx-3">International Journal of Education and Allied Sciences (IJEAS)</p>
        </div> */}

      {/* <div className='align-self-center mx-2'>


          <Button variant="outline-primary m-2 border-3" onClick={navigateToLogin} >Log In</Button>

          <Button variant="outline-primary m-2 border-3" onClick={navigateToRegister}>Register</Button>

        </div> */}


      {/* </div> */}

      <BrowserRouter>
        <Navbar />
        <Routes>

          <Route path='/' exact element={<Home />} />
          <Route path='/About' element={<About />} />
          <Route path='/Editorial' element={<Editorial />} />
          <Route path='/Guidelines' element={<Guidelines />} />
          <Route path='/Contact' element={<Contact />} />
          <Route path='/Login' element={<Login />} />
          <Route path='/Register' element={<Register />} />
          <Route path='/Year2009' element={<Year2009 />} />
          <Route path='/Year2010' element={<Year2010 />} />
          <Route path='/Year2011' element={<Year2011 />} />
          <Route path='/Year2012' element={<Year2012 />} />
          <Route path='/Year2013' element={<Year2013 />} />
          <Route path='/Year2014' element={<Year2014 />} />
          <Route path='/Year2015' element={<Year2015 />} />
          <Route path='/Year2016' element={<Year2016 />} />
          <Route path='/Year2017' element={<Year2017 />} />
          <Route path='/Year2018' element={<Year2018 />} />
          <Route path='/Year2019' element={<Year2019 />} />
          <Route path='/Year2020' element={<Year2020 />} />
          <Route path='/Year2021' element={<Year2021 />} />
          <Route path='/Year2022' element={<Year2022 />} />
          <Route path='/Year2023' element={<Year2023 />} />
          <Route path='/Year2024' element={<Year2024 />} />




        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
