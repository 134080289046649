import React, { useState } from 'react';
import { Container, Form, Button, InputGroup, Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { isEmail } from 'validator';
import { useNavigate } from 'react-router-dom';




const Register = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [name, setName] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [passwordIsValid, setPasswordIsValid] = useState(true);
    const schema = new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$');

    const navigate = useNavigate()

    const handleSubmit = (e) => {


        console.log('entered handle submit')

        e.preventDefault()


        if (!isEmail(email) ) {
            alert('Please enter a valid email address.');
            return;
        }
        else if(!passwordIsValid){

            alert("Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, and one digit.");
            return;

        }
        else {

            console.log('name,email,password', name, email, password)


            axios.post('http://localhost:3001/register', { name, email, password })
                .then(result => {
                    if (result.data === "successful") {
                        alert("Registered successful")
                        navigate('/Login')
                    }
                    if (result.data === "Account already exists") {
                        alert("Email-id alredy registered.")
                    }
                })
                .catch(err => console.log(err))


        }

    };



    const togglePasswordVisibility = () => {
        setShowPassword((prevState) => !prevState);
    };

    const handlePasswordChange = (e) => {
        const newPassword = e.target.value;
        setPassword(newPassword);
        validatePassword(newPassword);
    };

    const validatePassword = (password) => {
        const isValid = schema.test(password);
        setPasswordIsValid(isValid);
    };





    return (
        <Container className="mt-5 form p-5 rounded-5 border border-light" style={{ width: '50%' }}>
            <Form onSubmit={handleSubmit}>

                <Form.Group controlId="formBasicName">
                    <Form.Label className='p-1 fw-bold fs-5'>Name</Form.Label>
                    <Form.Control className='mb-3'
                        type="text"
                        placeholder="Enter your name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                </Form.Group>

                <Form.Group controlId="formBasicEmail">
                    <Form.Label className='p-1 fw-bold fs-5'>Email address</Form.Label>
                    <Form.Control className='mb-3'
                        type="email"
                        placeholder="Enter email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </Form.Group>

                <Form.Group controlId="formBasicPassword">
                    <Form.Label className='p-1 fw-bold fs-5'>Password</Form.Label>
                    <InputGroup>
                        <Form.Control className='mb-3' type={showPassword ? 'text' : 'password'} placeholder="Password" value={password} isInvalid={!passwordIsValid} onChange={handlePasswordChange} />

                        <Button variant="outline-light" id='eye' className='mb-3' onClick={togglePasswordVisibility}>
                            {showPassword ? <FiEyeOff /> : <FiEye />}
                        </Button>

                    </InputGroup>

                    
                    


                </Form.Group>






                <Button variant="primary" type="submit" className="m-3">
                    Create Account
                </Button>

                <Link to="/Login">

                    <Button variant="success" className="ml-2 m-3" >
                        Login
                    </Button>
                </Link>

            </Form>
        </Container>
    );
};

export default Register;

