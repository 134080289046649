import React, { Component } from 'react'
import { Image } from 'react-bootstrap';
// import front from '../front.jpeg';
import ijeas1 from '../ijeas1.jpg';
import ijeas2 from '../ijeas2.jpg';
import ijeas3 from '../ijeas3.jpg';
import ijeas4 from '../ijeas4.jpg';
// import back from '../back.jpeg';
import article1 from '../articles/Robin Singhal.pdf';
import article2 from '../articles/Beyond Literature_Kshitij Jain.pdf';
import article3 from '../articles/ijeas2022-7-14.pdf';
import article4 from '../articles/ijeas2022-15-22.pdf';
import article5 from '../articles/ijeas2022-23-37.pdf';
import article6 from '../articles/ijeas2022-38-52.pdf';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';



export default class Home extends Component {
  render() {
    return (
      <div className=' justify-content-center text-center my-5'>

        <Container fluid>
          <Row className='justify-content-center text-center m-4' >


            <Col style={{ width: "50%" }} className='rounded-4 border border-dark m-4  p-4'>
              <h4 className='my-3 fw-bold'>AIM and SCOPE</h4>
              <p>Our Aim is to provide a prestigious platform for scholars and researchers to showcase exceptional work and advancements in the fields of Education and Allied Sciences.</p>
              <p className='fw-bold'>Our Scope is:</p>
              <p>Original Research Papers: IJEAS welcomes original research papers that contribute to the advancement of knowledge in the field of Education and Allied Sciences.</p>
              <p>Comprehensive Articles: The journal publishes comprehensive articles that offer in-depth analyses and insights into various sub-areas within the field of education.</p>

            </Col>


            <Col style={{ width: "50%" }} className='rounded-4 border border-dark m-4  p-4'>
              <h4 className='fw-bold'>Recent Articles</h4>
              <div className='my-3'>
                <a href={article1} target='_blank' rel="noreferrer">1.Sustainability Transitions and Force Field Analysis by Robin Singhal</a><br></br>
              </div>
              <div className='my-3'>
                <a href={article2} target='_blank' rel="noreferrer">2.Beyond Literate Education by Kshitij Jain</a>
              </div>
              <div className='my-3'>
                <a href={article3} target='_blank' rel="noreferrer">3.Education through Aesthetic Imagination by Prof. Sudhakar Venukapalli</a><br></br>
              </div>
              <div className='my-3'>
                <a href={article4} target='_blank' rel="noreferrer">4.Bharat in Hindi Cinema: Iithaasa, Bhava, Abhinaya by Prof. Dhananjay Singh</a><br></br>
              </div>
              <div className='my-3'>
                <a href={article5} target='_blank' rel="noreferrer">5.Livestock Holdings and its Determinants: A Case Study of Haryana, India by Dr. Devesh Birwal & Dr. Deepti Taneja</a><br></br>
              </div>
              <div className='my-3'>
                <a href={article6} target='_blank' rel="noreferrer">6.Driver of Marine Economy in India: An Empirical Investigation of Fishery Sector by Prof. Mohammad Altaf Khan & Amulya Kumar Sahoo</a><br></br>
              </div>

            </Col>
          </Row>
        </Container>

        {/* <div className='d-flex justify-content-center text-center m-3' id='flex_container' >


          <div className='rounded-4 border border-dark m-3 p-4 home_content'>

            <h4 className='my-3 '>AIM and SCOPE</h4>
            <p>Our Aim is to provide a prestigious platform for scholars and researchers to showcase exceptional work and advancements in the fields of Education and Allied Sciences.</p>
            <p>Our Scope is:</p>
            <p>Original Research Papers: IJEAS welcomes original research papers that contribute to the advancement of knowledge in the field of Education and Allied Sciences.</p>
            <p>Comprehensive Articles: The journal publishes comprehensive articles that offer in-depth analyses and insights into various sub-areas within the field of education.</p>

          </div>



          <div className='rounded-4 border border-dark m-3 p-4 home_content'>

            <h4>Recent Articles</h4>
            <div className='my-3'>
              <a href={article1} target='_blank' rel="noreferrer">1.Education through Aesthetic Imagination by Prof. Sudhakar Venukapalli</a><br></br>
            </div>
            <div className='my-3'>
              <a href={article2} target='_blank' rel="noreferrer">2.Bharat in Hindi Cinema: Iithaasa, Bhava, Abhinaya by Prof. Dhananjay Singh</a><br></br>
            </div>
            <div className='my-3'>
              <a href={article3} target='_blank' rel="noreferrer">3.Livestock Holdings and its Determinants: A Case Study of Haryana, India by Dr. Devesh Birwal & Dr. Deepti Taneja</a><br></br>
            </div>
            <div className='my-3'>
              <a href={article4} target='_blank' rel="noreferrer">4.Driver of Marine Economy in India: An Empirical Investigation of Fishery Sector by Prof. Mohammad Altaf Khan & Amulya Kumar Sahoo</a><br></br>
            </div>
            <div className='my-3'>
              <a href={article5} target='_blank' rel="noreferrer">5.Agrarian - Institutional Reforms in Rajasthan in the Post- Independence Period by Anish Gupta & Mousumi Biswas</a><br></br>
            </div>
            <div className='my-3'>
              <a href={article6} target='_blank' rel="noreferrer">6.The Dying Tongues of India: Need for Protection by Sarah Imran</a>
            </div>

          </div>

        </div> */}

        <div style={{ height: '2px', backgroundColor: 'black' }}></div>

        <div className='row justify-content-center'>
          <div className='col-10 d-flex flex-nowrap overflow-auto justify-content-center'>



          {/* <Image src={front} width={170} height={170} className='m-3'></Image> */}
          <Image src={ijeas1} width={210} height={220} className='m-4'></Image>
          <Image src={ijeas2} width={210} height={220} className='m-4'></Image>
          <Image src={ijeas3} width={210} height={220} className='m-4'></Image>
          <Image src={ijeas4} width={210} height={220} className='m-4'></Image>
          {/* <Image src={back} width={170} height={170} className='m-3'></Image> */}

          </div>

        </div>

      </div>
    )
  }
}
