import React, { Component } from 'react'


export default class Editorial extends Component {
  render() {
    return (
      <div className='p-5'>

        <h4 className='my-3'>Editorial Board</h4>

        <p className="fst-italic mb-0">Prof. KB Das</p><p>Vice Chancellor, Central University of Jharkhand</p>

        <p className="fst-italic mb-0">Prof. Rishi Goel</p><p>Director, State Institute of Advanced Studies in Teacher Education (SIASTE), Gurugram</p>

        <p className="fst-italic mb-0">Dr. Madhusudan JV</p><p>Professor, Department of Education and Education Technology, University of Hyderabad</p>
        
        <p className="fst-italic mb-0">Prof. Vanaja. M</p><p>Professor at Maulana Azad National Urdu University, Hyderabad</p>
        
        <p className="fst-italic mb-0">Prof. Prem Narayan Singh</p><p>Director, Inter University Center for Teacher Education (IUCTE), BHU, Varanasi</p>
        
        <p className="fst-italic mb-0">Prof. Pankaj Arora</p><p>Chairperson, National Council for Teacher Education (NCTE), Delhi and Head & Dean, Department of Education (CIE), University of Delhi</p>
        
        <p className="fst-italic mb-0">Prof. Shiv Raj Singh</p><p>Professor & Head of Department of Mathematics, CCS University Meerut, Uttar Pradesh</p>
        
        <p className="fst-italic mb-0">Dr. Rajsharan Sahi</p><p>Associate Professor, Department of Education, Babasaheb Bhimrao Ambedkar University, Lucknow</p>
               
        <p className="fst-italic mb-0">Dr. Manjushree Sardespande</p><p>Associate Professor, Department of English. R.S. Mundle Dharampeth Arts & Commerce College, Nagpur</p>

        <p className="fst-italic mb-0">Dr. Selim Gündüz</p><p>Associate Professor, Department of Business, Adana Alparslan Turkes Science and Technology University</p>

        <p className="fst-italic mb-0">Dr. Fatma Feyza Gündüz</p><p>Associate Professor, Department of Business Administration, Adana Alparslan Turkes Science and Technology University</p>

        <p className="fst-italic mb-0">Dr. Anish Gupta</p><p>Associate Professor, Department of Economics, Delhi School of Economics</p>

        <h4>Chief Editor</h4>
        <p className="fst-italic mb-0">Prof. Sudhakar Venukapalli (Retired)</p><p>Professor, The English and Foreign Languages University (EFLU), Hyderabad</p>







      </div>
    )
  }
}
