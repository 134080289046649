import React, { useState } from 'react';
import { Container, Form, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';


const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  
  

  const navigate = useNavigate();

  

  axios.defaults.withCredentials = true;
  const handleSubmit = (e) => {
    e.preventDefault();
    axios.post('http://localhost:3001/login', { email, password })
      .then(result => {
        if (result.data === "successful") {
          navigate('/About');
          

        }
        else {
          alert("Wrong Credentials");
          
        }

      })
      .catch(err => console.log(err))

  };

  return (
    <Container className="mt-5 form border border-light p-5 rounded-5 " style={{ width: '50%' }}>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formBasicEmail">
          <Form.Label className='p-1 fw-bold fs-5'>Email address</Form.Label>
          <Form.Control className='mb-3'
            type="email"
            placeholder="Enter email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}

          />
        </Form.Group>

        <Form.Group controlId="formBasicPassword">
          <Form.Label className='p-1 fw-bold fs-5'>Password</Form.Label>
          <Form.Control className='mb-3'
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Form.Group>

        <Button variant="primary" type="submit" className=" m-3">
          Submit
        </Button>
        <Link to="/Register">
          <Button variant="success" className="ml-2 m-3">
            Create New Account
          </Button>
        </Link>
      </Form>
    </Container>
  );
};

export default Login;
