import React,{useState} from 'react'
import JournalManager from './JournalManager';
import { saveAs } from 'file-saver';
// import pdf1_2020 from '/Journals/ijeas2020.pdf';
// import pdf2_2020 from '/Journals/ijeas2021.pdf';

const Year2021 = () => {
  const pdfFiles = [{
    name: 'IJEAS-2021',
    url: '/Journals/ijeas2021.pdf'
  }
  ];

  const [selectedPDF, setSelectedPDF] = useState(null);

  const handlePreviewClick = (pdfUrl) => {
    setSelectedPDF(pdfUrl);
  };

  const handleDownloadClick = (pdfUrl, pdfName) => {
    saveAs(pdfUrl, pdfName);
  };

  return (
    <div className='m-5'>
      <h3>For the Journal, please email us on ijeas.submission@gmail.com</h3>
    </div>

  );
};

export default Year2021;

