import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
// import Image from 'react-bootstrap/Image';
// import Button from 'react-bootstrap/Button';
// import logo from '../IJEAS.jpg';
import { LinkContainer } from 'react-router-bootstrap';
import { NavDropdown } from 'react-bootstrap';
// import Login from './Login';
import { useNavigate } from 'react-router-dom';



const NavbarComp = () => {
    const navigate = useNavigate();

    const handleDropdownItemClick = (eventKey) => {

        navigate(`/${eventKey}`);
        console.log('year2023')
    };


    return (

        <>

            {/* <div className='d-flex flex-cloumn justify-content-between '>

                    <div className='d-flex align-item-start'>

                        <Image src={logo} width={100} height={100}></Image>
                        <p className="align-self-center">International Journal of Education and Allied Sciences</p>
                    </div>

                    <div className='align-item-end'>


                        <Button variant="outline-primary" size="sm">Log In</Button>
                        <Button variant="outline-primary" size="sm">Sign-Up</Button>
                    </div>


                </div> */}



            <Navbar expand="md" style={{maxWidth: "100%"}} className='navbar-dark bg-dark' id='navbar'>
                <Navbar.Toggle className='justify-content-end' aria-controls="basic-navbar-nav" />
                <Navbar.Collapse className='justify-content-center navbar-dark bg-dark' id="basic-navbar-nav">


                    <Nav className='ms-3'>

                        <LinkContainer to="/">
                            <Nav.Link >Home</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to="/About">
                            <Nav.Link >About</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to="/Editorial">
                            <Nav.Link >Editorial Team</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to="/Guidelines">
                            <Nav.Link >Author Guidelines</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to="/Issues">
                            <NavDropdown title="Browse Issues" id="basic-nav-dropdown" >
                                <NavDropdown.Item eventKey="Year2009" onClick={() => handleDropdownItemClick("Year2009")}>2009</NavDropdown.Item>
                                <NavDropdown.Item eventKey="Year2010" onClick={() => handleDropdownItemClick("Year2010")}>2010</NavDropdown.Item>
                                <NavDropdown.Item eventKey="Year2011" onClick={() => handleDropdownItemClick("Year2011")}>2011</NavDropdown.Item>
                                <NavDropdown.Item eventKey="Year2012" onClick={() => handleDropdownItemClick("Year2012")}>2012</NavDropdown.Item>
                                <NavDropdown.Item eventKey="Year2013" onClick={() => handleDropdownItemClick("Year2013")}>2013</NavDropdown.Item>
                                <NavDropdown.Item eventKey="Year2014" onClick={() => handleDropdownItemClick("Year2014")}>2014</NavDropdown.Item>
                                <NavDropdown.Item eventKey="Year2015" onClick={() => handleDropdownItemClick("Year2015")}>2015</NavDropdown.Item>
                                <NavDropdown.Item eventKey="Year2016" onClick={() => handleDropdownItemClick("Year2016")}>2016</NavDropdown.Item>
                                <NavDropdown.Item eventKey="Year2017" onClick={() => handleDropdownItemClick("Year2017")}>2017</NavDropdown.Item>
                                <NavDropdown.Item eventKey="Year2018" onClick={() => handleDropdownItemClick("Year2018")}>2018</NavDropdown.Item>
                                <NavDropdown.Item eventKey="Year2019" onClick={() => handleDropdownItemClick("Year2019")}>2019</NavDropdown.Item>

                                <NavDropdown.Item eventKey="Year2020" onClick={() => handleDropdownItemClick("Year2020")}>2020</NavDropdown.Item>
                                <NavDropdown.Item eventKey="Year2021" onClick={() => handleDropdownItemClick("Year2021")}>2021</NavDropdown.Item>
                                <NavDropdown.Item eventKey="Year2022" onClick={() => handleDropdownItemClick("Year2022")}>2022</NavDropdown.Item>
                                <NavDropdown.Item eventKey="Year2023" onClick={() => handleDropdownItemClick("Year2023")}>2023</NavDropdown.Item>
                                <NavDropdown.Item eventKey="Year2024" onClick={() => handleDropdownItemClick("Year2024")}>2024</NavDropdown.Item>
                            </NavDropdown>
                        </LinkContainer>
                        <LinkContainer to="/Contact">
                            <Nav.Link >Contact</Nav.Link>
                        </LinkContainer>




                    </Nav>
                </Navbar.Collapse>

            </Navbar>
        </>
    )
}


export default NavbarComp;

