// import React, { Component } from 'react';
// import Document from 'react-pdf-js'
// import {  Page } from 'react-pdf';
// import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

// export default class JournalManager extends Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//           numPages: 10,
//           currentPage: 1,
//           showPdf : false,
//           error: null
//         };

//       }

//       async componentDidMount() {
//         try {
//           // Dynamically import pdfjs
//           const pdfjs = await import('pdfjs-dist');
//           pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

//           this.setState({ showPDF: true });
//         } catch (error) {
//           this.setState({ error, showPDF: false });
//         }
//       }

//       onDocumentLoadSuccess = ({ numPages }) => {
//         this.setState({ numPages ,showPdf:true});
//       };

//       nextPage = () => {
//         const { currentPage, numPages } = this.state;
//         if (currentPage < numPages) {
//           this.setState({ currentPage: currentPage + 1 });
//         }
//       };

//       prevPage = () => {
//         const { currentPage } = this.state;
//         if (currentPage > 1) {
//           this.setState({ currentPage: currentPage - 1 });
//         }
//       };

//       render() {
//         const { pdfUrl } = this.props;
//         const { currentPage, numPages, showPdf, error } = this.state;
//         if (error) {
//             return <div>Error loading PDF: {error.message}</div>;
//           }

//         return (
//             <div>

//               <div>
//                 <Document file={pdfUrl} onLoadSuccess={this.onDocumentLoadSuccess}>
//                   <Page pageNumber={currentPage} />
//                 </Document>
//                 <div>
//                   <p>
//                     Page {currentPage} of {numPages}
//                   </p>
//                   <button onClick={this.prevPage} disabled={currentPage <= 1}>
//                     Previous
//                   </button>
//                   <button onClick={this.nextPage} disabled={currentPage >= numPages}>
//                     Next
//                   </button>
//                 </div>
//                 <a href={pdfUrl} download>
//                   Download PDF
//                 </a>
//               </div>

//           </div>
//         );
//       }
//     }


import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { Button } from 'react-bootstrap';


pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const JournalManager = ({ pdfUrl }) => {

  const [showPreview, setShowPreview] = useState(false);
  const [numPages, setNumPages] = useState(null);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const togglePreview = () => {
    setShowPreview((prevShowPreview) => !prevShowPreview);
  };



  return (
    <div className='m-4'>
      {/* <Document file={pdfUrl}>
        <Page pageNumber={6} />
      </Document> */}

      {showPreview ? (
        <div>
          <Document file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess}>
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', justifyContent: 'center'  }}>
              {Array.from(new Array(Math.min(numPages, 8)), (el, index) => (
                <Page key={`page_${index + 1}`} pageNumber={index + 1} width={350}  />
              ))}
            </div>
          </Document>
        </div>
      ) : null}
      <Button onClick={togglePreview} variant="primary">
        {showPreview ? 'Hide Preview' : 'Preview Top 8 Pages'}
      </Button>



    </div>

  )
}

export default JournalManager;

