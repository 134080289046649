import React, { Component } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';



export default class Contact extends Component {
  render() {
    return (

      <Container fluid style={{ padding: "0px,0px", margin: "0px, 0px" }}>

        <Row>
          <Col style={{ padding: "0px,0px", margin: "0px, 0px" }} className='text-center m-5'>
            <h4>For enquiry please e-mail us at :</h4>
            <a className='fs-5' href="mailto:ijeas.submission@gmail.com">ijeas.submission@gmail.com</a>
          </Col>
        </Row>

        <Row>
          <Col style={{ padding: "0px,0px", margin: "0px, 0px" }} className=' text-center m-3' >
            <p className='my-1 mx-5 fs-6 fw-bold'>Vishnu Achutha Menon</p>
            <p>+91 9746575542</p>
          </Col>
          <Col style={{ padding: "0px,0px", margin: "0px, 0px" }} className=' text-center m-3' >
            <p className='my-1 mx-5 fs-6 fw-bold'>Shubham Sharma</p>
            <p>+91 8882469648</p>
          </Col>
        </Row>

        <Row>
          <Col style={{ padding: "0px,0px", margin: "0px, 0px" }} className=' text-center m-5'>
            <h4>Address:</h4>
            <p>F-10 D, DDA Flats Munirka, Delhi: 110067</p>

          </Col>

        </Row>

      </Container>





      // <div className='d-flex flex-column text-center m-5'>
      //   <h4>For enquiry please e-mail us at :</h4>
      //   <a className='fs-5'>IEDS.query@gmail.com</a>

      //   <div className='d-flex flex-row justify-content-center text-center my-5'>
      //     <div>
      //     <p className='my-1 mx-5 fs-6 fw-bold'>Shubhangi Gupta</p>
      //     <p>9873513442</p>

      //     </div>


      //     <div>
      //       <p className='my-1 mx-5 fs-6 fw-bold'>Shubham Sharma</p>
      //       <p>8882469648</p>


      //     </div>

      //     <div>
      //       <p className='my-1 mx-5 fs-6 fw-bold'>Mukul</p>
      //       <p>9818459760</p>


      //     </div>
      //   </div>

      //   <div className='d-flex flex-column text-center m-5'>

      //     <h4>Address:</h4>
      //     <p><span className='fw-bold'>Noida Office:</span> IEDS, Shiksha Shodh Vikas Nyas, H-107 A, Sector 12, Noida, UP: 201301</p>
      //     <p><span className='fw-bold'>Delhi Office:</span> F-10 D, DDA Flats Munirka, Delhi: 110067</p>

      //   </div>

      // </div>
    )
  }
}
